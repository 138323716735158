import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  padding-top: 60px;
  /* padding-bottom: 30px; */
  /* padding-left: 30px; */
  /* padding-right: 30px; */
  z-index: 9999;
  overflow: hidden;
`;
