import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  Card,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  IconButton,
  Chip,
  CircularProgress,
  makeStyles,
} from '@material-ui/core';
import {
  Edit as EditIcon,
  Done as DoneIcon,
  Clear as DeleteIcon,
} from '@material-ui/icons';

import { useToast } from '../../../hooks/toast';
import api from '../../../services/api';

import Page from '../../../components/Layouts/Page';
import Toolbar from './Toolbar';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#F4F6F8',
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  rootCard: {
    backgroundColor: theme.palette.background.default,
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  chipActive: {
    background: theme.palette.success.main,
    // background: '#28a745',
    color: '#fff',
  },
  chipInactive: {
    background: theme.palette.error.main,
    color: '#fff',

    // background: '#d73a49',
  },
  tableContainer: {
    // maxHeight: 490,
    maxHeight: '45vh',
    // maxWidth: 'calc(100vw -900px)',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
}));

interface IUser {
  id: number;
  name: string;
  email: string;
  identification_document: string;
  job_title: string;
  formated_identification_document: string;
  cellphone_number: string;
  is_active: boolean;
  formated_last_login_at: string;
  formated_created_at: string;
  formated_updated_at: string;
}

const UsersList: React.FC = () => {
  const { addToast } = useToast();
  const navigate = useNavigate();
  const classes = useStyles();

  const typingDebounceRef = useRef<number | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [users, setUsers] = useState<IUser[]>([]);
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [resultsPerPage, setResultsPerPage] = useState(50);
  const [loading, setLoading] = useState(true);

  const handleChangePage = (event: unknown, newPage: number): void => {
    setPage(newPage + 1);
  };

  const handleChangeResultsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setResultsPerPage(+event.target.value);
    setPage(1);
  };

  const handleChangeSearchTerm = (newSearchTerm: string): void => {
    setSearchTerm(newSearchTerm);
  };

  const loadUsers = useCallback(async () => {
    try {
      setLoading(true);

      const response = await api.get('/admin/users', {
        params: {
          searchTerm,
          page,
          resultsPerPage,
        },
      });

      setUsers(response.data.users);
      setTotalRecords(response.data.totalRecords);
      setLoading(false);
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Erro',
        description: 'Erro ao carregar os dados',
      });
    }
  }, [searchTerm, page, resultsPerPage, addToast]);

  // search while typing
  useEffect(() => {
    if (typingDebounceRef.current) {
      clearTimeout(typingDebounceRef.current);
    }

    typingDebounceRef.current = setTimeout(() => {
      loadUsers();
    }, 600);
  }, [searchTerm, page, resultsPerPage,]); // eslint-disable-line

  return (
    <Page className={classes.root}>
      <Container maxWidth={false}>
        <Toolbar onSearch={handleChangeSearchTerm} />

        <Box mt={3}>
          <Card className={classes.rootCard}>
            {/* <CardHeader title="Certificados" /> */}
            <Box className={classes.tableWrapper}>
              <TableContainer className={classes.tableContainer}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" style={{ minWidth: 50 }}>
                        Ações
                      </TableCell>
                      <TableCell align="left">Nome</TableCell>

                      <TableCell align="left">Email</TableCell>

                      <TableCell align="left" style={{ minWidth: 150 }}>
                        Telefone
                      </TableCell>

                      <TableCell align="left">Cargo</TableCell>

                      <TableCell align="left">CPF</TableCell>

                      <TableCell align="left">Ativo</TableCell>

                      <TableCell align="left" style={{ minWidth: 150 }}>
                        Data Último Login
                      </TableCell>

                      <TableCell align="left">Data Criação</TableCell>

                      <TableCell align="left">Data Modificação</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!loading ? (
                      users.map(user => (
                        <TableRow hover key={user.id}>
                          <TableCell align="center">
                            <IconButton
                              color="primary"
                              onClick={() => {
                                navigate(`/users/edit/${user.id}`);
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                          </TableCell>

                          <TableCell>{user.name}</TableCell>

                          <TableCell>{user.email}</TableCell>

                          <TableCell>{user.cellphone_number}</TableCell>

                          <TableCell>{user.job_title}</TableCell>

                          <TableCell>
                            {user.formated_identification_document}
                          </TableCell>

                          <TableCell>
                            {user.is_active ? (
                              <Chip
                                icon={<DoneIcon style={{ color: '#fff' }} />}
                                label="ATIVO"
                                className={classes.chipActive}
                              />
                            ) : (
                              <Chip
                                icon={<DeleteIcon style={{ color: '#fff' }} />}
                                label="DESABILITADO"
                                className={classes.chipInactive}
                              />
                            )}
                          </TableCell>

                          <TableCell>{user.formated_last_login_at}</TableCell>

                          <TableCell>{user.formated_created_at}</TableCell>

                          <TableCell>{user.formated_updated_at}</TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell align="center" colSpan={10}>
                          <CircularProgress color="primary" size={100} />
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            <TablePagination
              rowsPerPageOptions={[50, 100, 150, 200]}
              component="div"
              count={totalRecords}
              rowsPerPage={resultsPerPage}
              page={page - 1}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeResultsPerPage}
            />
          </Card>
        </Box>
      </Container>
    </Page>
  );
};

export default UsersList;
