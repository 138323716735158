import React from 'react';
import { Routes, Route } from 'react-router-dom';

import UsersList from '../pages/Users/List';
import UsersCreate from '../pages/Users/Create';
import UsersEdit from '../pages/Users/Edit';

const UsersRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<UsersList />} />

      <Route path="create" element={<UsersCreate />} />

      <Route path="edit/:userId" element={<UsersEdit />} />
    </Routes>
  );
};

export default UsersRoutes;
