import React from 'react';
import { Routes, Route } from 'react-router-dom';

import CompaniesList from '../pages/Companies/List';
import CompaniesCreate from '../pages/Companies/Create';
import CompaniesEdit from '../pages/Companies/Edit';

const CompaniesRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<CompaniesList />} />

      <Route path="create" element={<CompaniesCreate />} />

      <Route path="edit/:companyId" element={<CompaniesEdit />} />
    </Routes>
  );
};

export default CompaniesRoutes;
