import React from 'react';
import { Routes, Route } from 'react-router-dom';

import CertificateList from '../pages/Certificates/List';
import CertificateCreate from '../pages/Certificates/Create';
import CertificateEdit from '../pages/Certificates/Edit';
import CertificateShow from '../pages/Certificates/Show';

const CertificatesRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<CertificateList />} />

      <Route path="create" element={<CertificateCreate />} />

      <Route path="edit/:certificateId" element={<CertificateEdit />} />

      <Route path="show/:certificateId" element={<CertificateShow />} />
    </Routes>
  );
};

export default CertificatesRoutes;
