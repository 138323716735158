import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  Card,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  IconButton,
  CircularProgress,
  makeStyles,
} from '@material-ui/core';
import { Edit as EditIcon } from '@material-ui/icons';

import { useToast } from '../../../hooks/toast';
import api from '../../../services/api';

import Page from '../../../components/Layouts/Page';
import Toolbar from './Toolbar';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#F4F6F8',
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  rootCard: {
    backgroundColor: theme.palette.background.default,
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  notPublished: {
    background: '#ffc4c4',
  },
  tableContainer: {
    // maxHeight: 490,
    maxHeight: '45vh',
    // maxWidth: 'calc(100vw -900px)',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
}));

interface ICompany {
  id: number;
  name: string;
  identification_code: string;
  formated_identification_code: string;
  formated_created_at: string;
  formated_updated_at: string;
}

const CompaniesList: React.FC = () => {
  const { addToast } = useToast();
  const navigate = useNavigate();
  const classes = useStyles();

  const typingDebounceRef = useRef<number | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [companies, setCompanies] = useState<ICompany[]>([]);
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [resultsPerPage, setResultsPerPage] = useState(50);
  const [loading, setLoading] = useState(true);

  const handleChangePage = (event: unknown, newPage: number): void => {
    setPage(newPage + 1);
  };

  const handleChangeResultsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setResultsPerPage(+event.target.value);
    setPage(1);
  };

  const handleChangeSearchTerm = (newSearchTerm: string): void => {
    setSearchTerm(newSearchTerm);
  };

  const loadCompanies = useCallback(async () => {
    try {
      setLoading(true);

      const response = await api.get('/admin/companies', {
        params: {
          searchTerm,
          page,
          resultsPerPage,
        },
      });

      setCompanies(response.data.companies);
      setTotalRecords(response.data.totalRecords);
      setLoading(false);
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Erro',
        description: 'Erro ao carregar os dados',
      });
    }
  }, [searchTerm, page, resultsPerPage, addToast]);

  // search while typing
  useEffect(() => {
    if (typingDebounceRef.current) {
      clearTimeout(typingDebounceRef.current);
    }

    typingDebounceRef.current = setTimeout(() => {
      loadCompanies();
    }, 600);
  }, [searchTerm, page, resultsPerPage,]); // eslint-disable-line

  return (
    <Page className={classes.root}>
      <Container maxWidth={false}>
        <Toolbar onSearch={handleChangeSearchTerm} />

        <Box mt={3}>
          <Card className={classes.rootCard}>
            {/* <CardHeader title="Certificados" /> */}
            <Box className={classes.tableWrapper}>
              <TableContainer className={classes.tableContainer}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" style={{ minWidth: 50 }}>
                        Ações
                      </TableCell>

                      <TableCell align="left">Empresa</TableCell>

                      <TableCell align="left">CNPJ</TableCell>

                      <TableCell align="left">Data Criação</TableCell>

                      <TableCell align="left">Data Modificação</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!loading ? (
                      companies.map(company => (
                        <TableRow hover key={company.id}>
                          <TableCell align="center">
                            <IconButton
                              color="primary"
                              onClick={() => {
                                navigate(`/companies/edit/${company.id}`);
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                          </TableCell>

                          <TableCell>{company.name}</TableCell>

                          <TableCell>
                            {company.formated_identification_code}
                          </TableCell>

                          <TableCell>{company.formated_created_at}</TableCell>

                          <TableCell>{company.formated_updated_at}</TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell align="center" colSpan={6}>
                          <CircularProgress color="primary" size={100} />
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            <TablePagination
              rowsPerPageOptions={[50, 100, 150, 200]}
              component="div"
              count={totalRecords}
              rowsPerPage={resultsPerPage}
              page={page - 1}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeResultsPerPage}
            />
          </Card>
        </Box>
      </Container>
    </Page>
  );
};

export default CompaniesList;
