import React, { useEffect, useRef, useState, useCallback } from 'react';
import 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';

import { useField } from '@unform/core';

import { DatePickerProps } from './types';
import { Container } from './styles';

const YearPickerInput: React.FC<DatePickerProps> = ({
  name,
  id,
  label,
  helperText,
  value,
  ...restProps
}) => {
  const { fieldName, registerField, defaultValue = null, error } = useField(
    name,
  );

  const inputRef = useRef(null);
  // const [inputValue, setInputValue] = useState<Date | null>(
  //   value
  //     ? new Date(Number(value), 0, 1)
  //     : defaultValue
  //     ? new Date(Number(defaultValue), 0, 1)
  //     : null,
  // );
  const [inputValue, setInputValue] = useState<Date | null>(
    value || defaultValue
      ? new Date(Number(value || defaultValue), 0, 1)
      : null,
  );

  const _handleChange = useCallback(
    (date: Date | null) => {
      setInputValue(date);
    },
    [setInputValue],
  );

  useEffect(() => {
    if (fieldName) {
      registerField({
        name: fieldName,
        ref: inputRef.current,
        // path: 'value',
        getValue() {
          // return Object.prototype.toString.call(inputValue) ===
          //   '[object Date]' && !Number.isNaN(inputValue)
          //   ? ''
          //   : inputValue?.getFullYear();
          return inputValue;
        },
        setValue(_, newValue: Date | null) {
          // console.log('registerField -> setValue =>', newValue);
          // if (ref) {
          //   ref.value = newValue;
          // }
          _handleChange(newValue);
        },
      });
    }
  }, [fieldName, registerField, _handleChange, inputValue]);

  return (
    <Container>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
        <DatePicker
          {...restProps}
          inputRef={inputRef}
          name={name}
          id={id}
          label={label}
          error={!!error}
          value={inputValue}
          views={['year']}
          onChange={_handleChange}
          helperText={error || helperText}
        />
      </MuiPickersUtilsProvider>
    </Container>
  );
};

export default React.memo(YearPickerInput);
