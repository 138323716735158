import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { apiRegisterIntercept } from '../services/api';
import DashboardLayout from '../components/Layouts/DashboardLayout';

import SignIn from '../pages/SignIn';
import ForgotPassword from '../pages/ForgotPassword';
import ResetPassword from '../pages/ResetPassword';
import Dashboard from '../pages/Dashboard';
import Profile from '../pages/Profile';
import CertificatesRoutes from './certificatesRoutes';
import CompaniesRoutes from './companiesRoutes';
import UsersRoutes from './usersRoutes';

import { useAuth } from '../hooks/auth';

apiRegisterIntercept();

const AppRoutes: React.FC = () => {
  const { user } = useAuth();

  return (
    <Routes>
      {user ? (
        <DashboardLayout>
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="profile" element={<Profile />} />

          <Route path="certificates/*" element={<CertificatesRoutes />} />

          <Route path="companies/*" element={<CompaniesRoutes />} />

          <Route path="users/*" element={<UsersRoutes />} />
        </DashboardLayout>
      ) : (
        <>
          <Route path="/" element={<SignIn />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="reset-password" element={<ResetPassword />} />
        </>
      )}
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default AppRoutes;
