import React, { useCallback, useState, useEffect } from 'react';
import {
  Container,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Divider,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  IconButton,
  Typography,
  CircularProgress,
  colors,
  makeStyles,
} from '@material-ui/core';
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
  PictureAsPdf as DownloadIcon,
} from '@material-ui/icons';
import { useParams } from 'react-router-dom';
import QRCode from 'qrcode.react';

import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';
import { getApiErrors } from '../../../utils/getErrors';

import Page from '../../../components/Layouts/Page';
import Label from '../../../components/Label';

interface ICompany {
  id: number;
  name: string;
  identification_code: string;
  formated_identification_code: string;
}

interface ICertificateType {
  id: number;
  short_type: string;
  type: string;
}

interface ICertificationCategory {
  id: number;
  category: string;
}

interface ICertificateSituation {
  id: number;
  situation: string;
}

interface ICertificate {
  id: number;
  hash_id: string;
  company_id: number;
  certificate_type_id: number;
  contract_year: number;
  revision_number: number;
  scope: string;
  normatives: string;
  certification_category_id: number;
  first_concession: Date;
  validity_start: Date;
  validity_end: Date;
  certificate_situation_id: number;
  is_published: boolean;
  certificate_number_old: string;
  certificate_file: string;
  created_at: Date;
  updated_at: Date;
  formated_certificate_code: string;
  formated_first_concession: string;
  formated_validity_start: string;
  formated_validity_end: string;

  company: ICompany;
  certificate_type: ICertificateType;
  certification_category: ICertificationCategory;
  certificate_situation: ICertificateSituation;
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#F4F6F8',
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  cardQr: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tableLineTitle: {
    fontWeight: 'bold',
  },
}));

const CertificateShow: React.FC = () => {
  const { addToast } = useToast();
  const { certificateId } = useParams();
  const classes = useStyles();

  const [loadingCertificate, setLoadingCertificate] = useState<boolean>(true);
  const [certificate, setCertificate] = useState<ICertificate>(
    {} as ICertificate,
  );

  const setLabelColor = (id: number): string => {
    switch (id) {
      case 1:
        return colors.green[600];
      case 2:
        return colors.red[600];
      case 3:
        return colors.orange[600];
      default:
        return colors.red[900];
    }
  };

  async function handleDownload(hash_id: string): Promise<void> {
    try {
      const downloadResponse = await api.get(
        `/admin/certificates/download/${hash_id}`,
        { responseType: 'blob' },
      );

      const url = window.URL.createObjectURL(downloadResponse.data);
      const a = document.createElement('a');
      a.href = url;
      a.download = `certificado_${hash_id}.pdf`;
      a.click();
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Erro',
        // description: 'Não foi possível acessar o certificado solicitado',
        description: getApiErrors(err),
      });
    }
  }

  const loadCertificate = useCallback(async () => {
    setLoadingCertificate(true);
    const response = await api.get(`/admin/certificates/${certificateId}`);
    setCertificate(response.data?.certificate);
    setLoadingCertificate(false);
  }, [certificateId]);

  useEffect(() => {
    loadCertificate();
  }, [loadCertificate]);

  return (
    <Page className={classes.root}>
      <Container maxWidth="lg">
        <Grid container spacing={1}>
          <Grid item lg={8} md={8} xs={12}>
            <Card>
              <CardHeader subheader="Exibir" title="Certificado" />

              <Divider />
              {!loadingCertificate ? (
                <CardContent>
                  <Grid container spacing={1}>
                    <Grid item md={12} xs={12}>
                      <TableContainer>
                        <Table aria-label="sticky table">
                          <TableBody>
                            <TableRow>
                              <TableCell>
                                <Typography
                                  gutterBottom
                                  variant="h5"
                                  className={classes.tableLineTitle}
                                >
                                  Empresa
                                </Typography>
                              </TableCell>
                              <TableCell>
                                {certificate?.company.name} -{' '}
                                {
                                  certificate?.company
                                    .formated_identification_code
                                }
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell>
                                <Typography
                                  gutterBottom
                                  variant="h5"
                                  className={classes.tableLineTitle}
                                >
                                  Nº Certificado
                                </Typography>
                              </TableCell>
                              <TableCell>
                                {certificate?.formated_certificate_code}
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell>
                                <Typography
                                  gutterBottom
                                  variant="h5"
                                  className={classes.tableLineTitle}
                                >
                                  Identificador
                                </Typography>
                              </TableCell>
                              <TableCell>{certificate?.hash_id}</TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell>
                                <Typography
                                  gutterBottom
                                  variant="h5"
                                  className={classes.tableLineTitle}
                                >
                                  Tipo do Certificado
                                </Typography>
                              </TableCell>
                              <TableCell>
                                {`${certificate?.certificate_type.type} - (${certificate?.certificate_type.short_type})`}
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell>
                                <Typography
                                  gutterBottom
                                  variant="h5"
                                  className={classes.tableLineTitle}
                                >
                                  Categoria da Certificação
                                </Typography>
                              </TableCell>
                              <TableCell>
                                {certificate?.certification_category?.category}
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell>
                                <Typography
                                  gutterBottom
                                  variant="h5"
                                  className={classes.tableLineTitle}
                                >
                                  Ano do Contrato
                                </Typography>
                              </TableCell>
                              <TableCell>
                                {certificate?.contract_year}
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell>
                                <Typography
                                  gutterBottom
                                  variant="h5"
                                  className={classes.tableLineTitle}
                                >
                                  Revisão
                                </Typography>
                              </TableCell>
                              <TableCell>
                                {certificate?.revision_number}
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell>
                                <Typography
                                  gutterBottom
                                  variant="h5"
                                  className={classes.tableLineTitle}
                                >
                                  Escopo
                                </Typography>
                              </TableCell>
                              <TableCell>{certificate?.scope}</TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell>
                                <Typography
                                  gutterBottom
                                  variant="h5"
                                  className={classes.tableLineTitle}
                                >
                                  Normativas
                                </Typography>
                              </TableCell>
                              <TableCell>{certificate?.normatives}</TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell>
                                <Typography
                                  gutterBottom
                                  variant="h5"
                                  className={classes.tableLineTitle}
                                >
                                  Primeira Concessão
                                </Typography>
                              </TableCell>
                              <TableCell>
                                {certificate?.formated_first_concession}
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell>
                                <Typography
                                  gutterBottom
                                  variant="h5"
                                  className={classes.tableLineTitle}
                                >
                                  Período de Validade
                                </Typography>
                              </TableCell>
                              <TableCell>
                                {certificate?.formated_validity_start
                                  ? `${certificate?.formated_validity_start} até\n ${certificate?.formated_validity_end}`
                                  : '-'}
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell>
                                <Typography
                                  gutterBottom
                                  variant="h5"
                                  className={classes.tableLineTitle}
                                >
                                  Situação
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Label
                                  color={setLabelColor(
                                    certificate?.certificate_situation_id,
                                  )}
                                >
                                  {certificate?.certificate_situation
                                    ?.situation ?? '-'}
                                </Label>
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell>
                                <Typography
                                  gutterBottom
                                  variant="h5"
                                  className={classes.tableLineTitle}
                                >
                                  Anexo do Certificado
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <IconButton
                                  color="primary"
                                  disabled={!certificate.certificate_file}
                                  onClick={() => {
                                    handleDownload(certificate.hash_id);
                                  }}
                                >
                                  <DownloadIcon fontSize="large" />
                                  Download
                                </IconButton>
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell>
                                <Typography
                                  gutterBottom
                                  variant="h5"
                                  className={classes.tableLineTitle}
                                >
                                  Publicado
                                </Typography>
                              </TableCell>
                              <TableCell>
                                {certificate.is_published ? (
                                  <VisibilityIcon fontSize="large" />
                                ) : (
                                  <VisibilityOffIcon fontSize="large" />
                                )}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                </CardContent>
              ) : (
                <CircularProgress color="primary" size={100} />
              )}
            </Card>
          </Grid>

          <Grid item lg={4} md={4} xs={12}>
            <Card>
              <CardHeader title="QRCODE" />

              <Divider />
              {!loadingCertificate ? (
                <CardContent className={classes.cardQr}>
                  <QRCode
                    value={
                      certificate?.hash_id &&
                      `${process.env.REACT_APP_WEB_URL}/certificados/exibir/${certificate?.hash_id}`
                    }
                    size={256}
                    bgColor="#ffffff"
                    fgColor="#000000"
                    level="H"
                    includeMargin={false}
                    renderAs="canvas"
                    // imageSettings={{
                    //   src: `${process.env.REACT_APP_ADMIN_URL}/logo-q.svg`,
                    //   // x: undefined,
                    //   // y: undefined,
                    //   height: 70,
                    //   width: 70,
                    //   excavate: true,
                    // }}
                  />
                </CardContent>
              ) : (
                <CircularProgress color="primary" size={100} />
              )}
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default CertificateShow;
