import React, { useRef, useCallback } from 'react';
import {
  Container,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Box,
  Button,
  makeStyles,
} from '@material-ui/core';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useNavigate } from 'react-router-dom';

import api from '../../../services/api';
import Yup from '../../../utils/validators/Yup';
import { getValidationErrors, getApiErrors } from '../../../utils/getErrors';
import { useToast } from '../../../hooks/toast';

import Page from '../../../components/Layouts/Page';
import TextInput from '../../../components/Form/TextInput';
import TextInputMask from '../../../components/Form/TextInputMask';

interface CompanyFormData {
  name: string;
  identification_code: string;
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#F4F6F8',
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const CompanyCreate: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const navigate = useNavigate();
  const classes = useStyles();

  const handleSubmit = useCallback(
    async (data: CompanyFormData) => {
      try {
        formRef.current?.setErrors({});

        const schemaForm = Yup.object().shape({
          name: Yup.string().required('Campo obrigatório'),
          identification_code: Yup.string()
            .isCNPJ('CNPJ inválido')
            .required('CPF é obrigatório'),
        });

        await schemaForm.validate(data, { abortEarly: false });

        const { name, identification_code } = data;

        const formData = { name, identification_code };

        await api.post(`/admin/companies/`, formData);

        addToast({
          type: 'success',
          title: 'Sucesso!',
          description: 'Empresa cadastrada com sucesso!',
        });

        navigate('/companies');
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          // console.log('Yup.ValidationError => ', error);

          formRef.current?.setErrors(errors);

          addToast({
            type: 'error',
            title: 'Erro',
            delay: 10000,
            description: 'Verifique os erros no formulário',
          });
          return;
        }

        addToast({
          type: 'error',
          title: 'Erro',
          delay: 10000,
          description: getApiErrors(error),
        });
      }
    },
    [addToast, navigate],
  );

  return (
    <Page className={classes.root}>
      <Container maxWidth="lg">
        <Grid container spacing={1}>
          <Grid item lg={12} md={12} xs={12}>
            <Form
              ref={formRef}
              onSubmit={handleSubmit}
              autoComplete="off"
              noValidate
              className={classes.root}
            >
              <Card>
                <CardHeader title="Empresa" subheader="Cadastrar" />

                <Divider />
                <CardContent>
                  <Grid container spacing={1}>
                    <Grid item md={12} xs={12}>
                      <TextInput
                        name="name"
                        label="Razão Social"
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>

                    <Grid item md={12} xs={12}>
                      <TextInputMask
                        name="identification_code"
                        placeholder="CNPJ"
                        mask="99.999.999/9999-99"
                      />
                    </Grid>
                  </Grid>
                </CardContent>

                <Divider />

                <Box display="flex" justifyContent="flex-end" p={2}>
                  <Button type="submit" color="primary" variant="contained">
                    Salvar
                  </Button>
                </Box>
              </Card>
            </Form>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default CompanyCreate;
