import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;

  > label {
    margin-bottom: 10px;
  }

  /* label {
    cursor: pointer;
    input {
      display: none;
    }
  } */
`;
