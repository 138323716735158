import React, { useRef, useCallback, useState, useEffect } from 'react';
import {
  Container,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Box,
  Button,
  CircularProgress,
  makeStyles,
} from '@material-ui/core';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useNavigate, useParams } from 'react-router-dom';

import api from '../../../services/api';
import Yup from '../../../utils/validators/Yup';
import { getValidationErrors, getApiErrors } from '../../../utils/getErrors';
import { useToast } from '../../../hooks/toast';

import Page from '../../../components/Layouts/Page';
import TextInput from '../../../components/Form/TextInput';
import TextInputMask from '../../../components/Form/TextInputMask';
import Switch from '../../../components/Form/Switch';
import Autocomplete from '../../../components/Form/Autocomplete';

interface ICompany {
  id: number;
  name: string;
  identification_code: string;
  formated_identification_code: string;
}

interface IRole {
  id: number;
  role?: string;
  alias?: string;
}

interface IUserCompany {
  id: number;
  company_id: number;
}

interface IUserRole {
  id: number;
  role_id: number;
}

interface UserFormData {
  name: string;
  identification_document: string;
  job_title: string;
  cellphone_number: string;
  email: string;
  password: string;
  is_active: boolean;
  companies: string[];
  roles: string[];
}

interface AutocompleteOptions {
  label: string;
  value: string | number;
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#F4F6F8',
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const UserEdit: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const navigate = useNavigate();
  const { userId } = useParams();
  const classes = useStyles();

  const [companies, setCompanies] = useState<AutocompleteOptions[]>([]);
  const [loadingCompanies, setLoadingCompanies] = useState<boolean>(true);
  const [companiesSearchTerm] = useState<string>('');

  const [roles, setRoles] = useState<AutocompleteOptions[]>([]);
  const [loadingRoles, setLoadingRoles] = useState<boolean>(true);
  const [rolesSearchTerm] = useState<string>('');

  const [loadingUser, setLoadingUser] = useState<boolean>(true);
  const [user, setUser] = useState({});

  const loadCompanies = useCallback(async () => {
    setLoadingCompanies(true);
    const response = await api.get('/admin/companies', {
      params: {
        companiesSearchTerm,
        // page,
        // resultsPerPage,
      },
    });

    const formatedCompanies = response.data?.companies?.map(
      (company: ICompany) => ({
        label: `${company.name} - (${company.identification_code})`,
        value: company.id,
      }),
    );

    setCompanies(formatedCompanies);
    setLoadingCompanies(false);
  }, [companiesSearchTerm]);

  const loadRoles = useCallback(async () => {
    setLoadingRoles(true);
    const response = await api.get('/admin/roles', {
      params: {
        rolesSearchTerm,
        // page,
        // resultsPerPage,
      },
    });

    const formatedRoles = response.data?.roles?.map((role: IRole) => ({
      label: role.role,
      value: role.id,
    }));

    setRoles(formatedRoles);
    setLoadingRoles(false);
  }, [rolesSearchTerm]);

  const loadUser = useCallback(async () => {
    setLoadingUser(true);
    try {
      const response = await api.get(`/admin/users/${userId}`);

      const formatedUser = {
        ...response.data?.user,
        companies: response.data?.user?.userCompanies.map(
          (company: IUserCompany) => company.company_id,
        ),
        roles: response.data?.user?.userRoles.map(
          (role: IUserRole) => role.role_id,
        ),
      };

      setUser(formatedUser);
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Erro',
        delay: 10000,
        description: 'Erro ao carregar os dados do usuário',
      });
    }
    setLoadingUser(false);
  }, [userId, addToast]);

  const handleSubmit = useCallback(
    async (data: UserFormData) => {
      try {
        formRef.current?.setErrors({});

        const schemaForm = Yup.object().shape({
          name: Yup.string().required('Campo obrigatório'),
          job_title: Yup.string().required('Campo obrigatório'),
          cellphone_number: Yup.string().required('Campo obrigatório'),
          identification_document: Yup.string()
            .isCPF('CPF inválido')
            .required('Campo obrigatório'),
          email: Yup.string()
            .required('Campo obrigatório')
            .email('Digite um e-mail válido'),
          password: Yup.string().test(
            'minPasswordLengthRequired',
            'Minimo de 6 caracteres',
            value => {
              if (value) {
                return value?.length > 5;
              }
              return true;
            },
          ),
          companies: Yup.array()
            .of(Yup.string().required())
            // .strict(true)
            .nullable()
            .required('Campo obrigatório'),
          roles: Yup.array()
            .of(Yup.string().required())
            // .strict(true)
            .nullable()
            .required('Campo obrigatório'),
        });

        await schemaForm.validate(data, { abortEarly: false });

        const {
          name,
          job_title,
          cellphone_number,
          identification_document,
          email,
          password,
          is_active,
          companies: companiesIds,
          roles: rolesIds,
        } = data;

        const formData = {
          name,
          job_title,
          cellphone_number,
          identification_document,
          email,
          password,
          is_active,
          companies: companiesIds,
          roles: rolesIds,
        };

        await api.put(`/admin/users/${userId}`, formData);

        addToast({
          type: 'success',
          title: 'Sucesso!',
          description: 'Dados do usuário atualizados com sucesso!',
        });

        navigate('/users');
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          // console.log('Yup.ValidationError => ', error);

          formRef.current?.setErrors(errors);

          addToast({
            type: 'error',
            title: 'Erro',
            delay: 10000,
            description: 'Verifique os erros no formulário',
          });
          return;
        }

        addToast({
          type: 'error',
          title: 'Erro',
          delay: 10000,
          description: getApiErrors(error),
        });
      }
    },
    [addToast, navigate, userId],
  );

  useEffect(() => {
    loadUser();
    loadCompanies();
    loadRoles();
  }, [loadUser, loadCompanies, loadRoles]);

  return (
    <Page className={classes.root}>
      <Container maxWidth="lg">
        <Grid container spacing={1}>
          <Grid item lg={12} md={12} xs={12}>
            {!loadingUser && !loadingCompanies && !loadingRoles ? (
              <Form
                ref={formRef}
                initialData={user}
                onSubmit={handleSubmit}
                autoComplete="off"
                noValidate
                className={classes.root}
              >
                <Card>
                  <CardHeader title="Usuário" subheader="Editar" />

                  <Divider />

                  <CardContent>
                    <Grid container spacing={1}>
                      <Grid item md={12} xs={12}>
                        <TextInput
                          name="name"
                          label="Nome"
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>

                      <Grid item md={12} xs={12}>
                        <TextInput
                          name="email"
                          label="Email"
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>

                      <Grid item md={12} xs={12}>
                        <TextInputMask
                          name="identification_document"
                          placeholder="CPF"
                          mask="999.999.999-99"
                        />
                      </Grid>

                      <Grid item md={12} xs={12}>
                        <TextInput
                          name="job_title"
                          label="Cargo / Função"
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>

                      <Grid item md={12} xs={12}>
                        <TextInputMask
                          name="cellphone_number"
                          placeholder="Celular"
                          mask="(99) 99999-9999"
                        />
                      </Grid>

                      <Grid item md={12} xs={12}>
                        <TextInput
                          name="password"
                          label="Senha"
                          type="password"
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>

                      <Grid item md={12} xs={12}>
                        <Switch
                          name="is_active"
                          legend="Usuário Ativo"
                          label="Usuário Habilitado?"
                          helpText="Quando habilitado o usuário pode efetuar login e utilizar o sistema"
                          // checked={isPublished}
                          // onChange={handleChangeIsPublished}
                          size="medium"
                          color="primary"
                        />
                      </Grid>

                      <Grid item md={12} xs={12}>
                        <Autocomplete
                          id="combo_companies"
                          name="companies"
                          label="Empresas"
                          noOptionsText="Nenhum registro encontrado"
                          multiple
                          required
                          options={companies}
                          loading={loadingCompanies}
                        />
                      </Grid>

                      <Grid item md={12} xs={12}>
                        <Autocomplete
                          id="combo_roles"
                          name="roles"
                          label="Grupos Acesso"
                          noOptionsText="Nenhum registro encontrado"
                          multiple
                          required
                          options={roles}
                          loading={loadingRoles}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>

                  <Divider />

                  <Box display="flex" justifyContent="flex-end" p={2}>
                    <Button type="submit" color="primary" variant="contained">
                      Salvar
                    </Button>
                  </Box>
                </Card>
              </Form>
            ) : (
              <CircularProgress color="primary" size={100} />
            )}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default UserEdit;
