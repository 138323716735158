import React from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';

import {
  FiSearch as SearchIcon,
  // FiUpload as ImportExportIcon,
} from 'react-icons/fi';

const useStyles = makeStyles(theme => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
}));

interface ToolbarProps {
  className?: string;
  onSearch(value: string): void;
}

const Toolbar: React.FC<ToolbarProps> = ({ onSearch, className, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Grid alignItems="flex-end" container justify="space-between" spacing={3}>
        <Grid item>
          {/* <Typography component="h2" gutterBottom variant="overline">
            Management
          </Typography> */}
          <Typography component="h1" variant="h3">
            Empresas
          </Typography>
        </Grid>
        {/* <Grid item>
          <Button color="primary" variant="contained">
            Add customer
          </Button>
        </Grid> */}
      </Grid>

      <Box display="flex" justifyContent="flex-end">
        {/* <Button className={classes.importButton}>
          <ImportExportIcon />
          Importar
        </Button>
        <Button className={classes.exportButton}>
          <ImportExportIcon />
          Exportar
        </Button> */}
        <Button
          onClick={() => navigate('/companies/create')}
          color="primary"
          variant="contained"
        >
          Cadastrar Novo
        </Button>
      </Box>
      <Box mt={3}>
        <Card>
          <CardContent>
            <Box maxWidth={500}>
              <TextField
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  onSearch(event.target.value);
                }}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  ),
                }}
                placeholder="Procurar"
                variant="outlined"
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

export default Toolbar;
