import styled, { css } from 'styled-components';

import Tooltip from '../../Tooltip';

interface ContainerProps {
  isFocused: boolean;
  isField: boolean;
  isErrored: boolean;
}

export const Container = styled.div<ContainerProps>`
  background: #fff;
  border-radius: 4px;
  padding: 0px 16px 0px 16px;
  width: 100%;

  border: 0.5px solid #c2c2c2;

  display: flex;
  align-items: center;

  & + div {
    margin-top: 8px;
  }

  &:hover {
    border: 1px solid #000;
  }

  ${props =>
    props.isErrored &&
    css`
      border-color: ${props.theme.colors.red};
    `}

  ${props =>
    props.isFocused &&
    css`
      border: 2px solid #3f51b5;
    `}


  input {
    flex: 1;
    padding: 16px 0px 16px 0px;
    background: transparent;
    border: 0;
    color: #263238;
    font-family: ${`"Roboto", "Helvetica", "Arial", sans-serif`};
    font-size: 16px;

    &::placeholder {
      color: #263238;
    }
  }

  svg {
    margin-right: 16px;
  }
`;

export const Error = styled(Tooltip)`
  height: 20px;
  margin-left: 16px;
  svg {
    margin: 0;
  }

  span {
    background: ${props => props.theme.colors.red};
    color: ${props => props.theme.colors.whiteLight};

    &::before {
      border-color: ${props => props.theme.colors.red} transparent;
    }
  }
`;
