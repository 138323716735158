import React, { useRef, useCallback, useState, useEffect } from 'react';
import {
  Container,
  Grid,
  Card,
  CardHeader,
  CardContent,
  // CardActions,
  Divider,
  Box,
  Button,
  makeStyles,
} from '@material-ui/core';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useNavigate } from 'react-router-dom';

import api from '../../../services/api';
import Yup from '../../../utils/validators/Yup';
import { getValidationErrors, getApiErrors } from '../../../utils/getErrors';
import { useToast } from '../../../hooks/toast';

import Page from '../../../components/Layouts/Page';
import TextInput from '../../../components/Form/TextInput';
import Switch from '../../../components/Form/Switch';
import FileInput from '../../../components/Form/FileInput';
import Autocomplete from '../../../components/Form/Autocomplete';
import DatePickerInput from '../../../components/Form/DatePickerInput';
import YearPickerInput from '../../../components/Form/YearPickerInput';

interface ICompany {
  id: number;
  name: string;
  identification_code: string;
  formated_identification_code: string;
}

interface AutocompleteOptions {
  label: string;
  value: string | number;
}

interface CertificateFormData {
  company_id: number;
  certificate_type_id: number;
  certification_category_id: number;
  contract_year: Date;
  revision_number: number;
  scope: string;
  normatives: string;
  certificate_situation_id: number;
  first_concession: Date;
  validity_start: Date;
  validity_end: Date;
  certificate_file: File;
  is_published: boolean;
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#F4F6F8',
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const CertificateCreate: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const navigate = useNavigate();

  const classes = useStyles();

  const optionsCertificateSituations = [
    { value: 1, label: 'Ativo' },
    { value: 2, label: 'Cancelado' },
    { value: 3, label: 'Suspenso' },
    { value: 4, label: 'Expirado' },
  ];

  const optionsCertificateTypes = [
    { value: 1, label: 'CP - Certificação de Produto' },
    { value: 2, label: 'AS - Acreditação de OPS' },
    { value: 3, label: 'CS - Certificação de Serviços' },
    { value: 4, label: 'CR - Certificação de Rotulagem Ambiental' },
    { value: 5, label: 'AC - Avaliação da Conformidade' },
  ];

  const optionsCertificateCategories = [
    { value: 1, label: 'Certificação Compulsória' },
    { value: 2, label: 'Certificação Voluntária sem Escopo Acreditado' },
    { value: 3, label: 'Certificação Voluntária com Escopo Acreditado' },
    { value: 4, label: 'Acreditação OPS Nível I' },
    { value: 5, label: 'Acreditação OPS Nível II' },
    { value: 6, label: 'Acreditação OPS Nível III' },
  ];

  const [companies, setCompanies] = useState<AutocompleteOptions[]>([]);
  const [loadingCompanies, setLoadingCompanies] = useState<boolean>(true);
  // setCompaniesSearchTerm
  const [companiesSearchTerm] = useState<string>('');

  const handleSubmit = useCallback(
    async (data: CertificateFormData) => {
      try {
        formRef.current?.setErrors({});

        const schemaForm = Yup.object().shape({
          company_id: Yup.string().nullable().required('Campo obrigatório'),
          certificate_type_id: Yup.string()
            .nullable()
            .required('Campo obrigatório'),
          contract_year: Yup.date()
            .typeError('Data Inválida')
            .required('Campo obrigatório'),
          first_concession: Yup.date()
            .nullable()
            .transform((value, originalValue) =>
              originalValue &&
              Object.prototype.toString.call(originalValue) ===
                '[object Date]' &&
              !Number.isNaN(originalValue)
                ? null
                : value,
            ),
          validity_start: Yup.date()
            .nullable()
            .transform((value, originalValue) =>
              originalValue &&
              Object.prototype.toString.call(originalValue) ===
                '[object Date]' &&
              !Number.isNaN(originalValue)
                ? null
                : value,
            ),
          validity_end: Yup.date()
            .nullable()
            .transform((value, originalValue) =>
              originalValue &&
              Object.prototype.toString.call(originalValue) ===
                '[object Date]' &&
              !Number.isNaN(originalValue)
                ? null
                : value,
            ),
        });

        await schemaForm.validate(data, { abortEarly: false });

        const {
          company_id,
          certificate_type_id,
          certification_category_id,
          contract_year,
          revision_number,
          scope,
          normatives,
          certificate_situation_id,
          first_concession,
          validity_start,
          validity_end,
          certificate_file,
          is_published,
        } = data;

        const formData = new FormData();
        formData.append('company_id', String(company_id));
        formData.append('certificate_type_id', String(certificate_type_id));
        formData.append(
          'certification_category_id',
          String(certification_category_id ?? ''),
        );
        formData.append('contract_year', String(contract_year.getFullYear()));
        formData.append('revision_number', String(revision_number));
        formData.append('scope', scope);
        formData.append('normatives', normatives);
        formData.append(
          'certificate_situation_id',
          String(certificate_situation_id ?? ''),
        );
        formData.append('first_concession', String(first_concession ?? ''));
        formData.append('validity_start', String(validity_start ?? ''));
        formData.append('validity_end', String(validity_end ?? ''));

        if (certificate_file) {
          formData.append('certificate_file', certificate_file);
        }
        formData.append('is_published', String(is_published));

        await api.post('/admin/certificates', formData);

        addToast({
          type: 'success',
          title: 'Sucesso!',
          description: 'Certificado cadastrado com sucesso!',
        });

        navigate('/certificates');
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          // console.log('Yup.ValidationError => ', error);

          formRef.current?.setErrors(errors);

          addToast({
            type: 'error',
            title: 'Erro',
            delay: 10000,
            description: 'Verifique os erros no formulário',
          });
          return;
        }

        addToast({
          type: 'error',
          title: 'Erro',
          delay: 10000,
          description: getApiErrors(error),
        });
      }
    },
    [addToast, navigate],
  );

  const loadCompanies = useCallback(async () => {
    setLoadingCompanies(true);
    const response = await api.get('/admin/companies', {
      params: {
        companiesSearchTerm,
        // page,
        // resultsPerPage,
      },
    });

    const formatedCompanies = response.data?.companies?.map(
      (company: ICompany) => ({
        label: `${company.name} - (${company.identification_code})`,
        value: company.id,
      }),
    );

    setCompanies(formatedCompanies);
    setLoadingCompanies(false);
  }, [companiesSearchTerm]);

  useEffect(() => {
    loadCompanies();
  }, [loadCompanies]);

  return (
    <Page className={classes.root}>
      <Container maxWidth="lg">
        <Grid container spacing={1}>
          <Grid item lg={12} md={12} xs={12}>
            {/* <form autoComplete="off" noValidate className={classes.root}> */}
            <Form
              ref={formRef}
              onSubmit={handleSubmit}
              autoComplete="off"
              noValidate
              className={classes.root}
            >
              <Card>
                <CardHeader subheader="Cadastro" title="Certificado" />

                <Divider />

                <CardContent>
                  <Grid container spacing={1}>
                    <Grid item md={12} xs={12}>
                      <Autocomplete
                        id="combo_company_id"
                        name="company_id"
                        label="Cliente"
                        noOptionsText="Nenhum registro encontrado"
                        // multiple
                        required
                        options={companies}
                        loading={loadingCompanies}
                      />
                    </Grid>

                    <Grid item md={12} xs={12}>
                      <Autocomplete
                        id="combo_certificate_type_id"
                        name="certificate_type_id"
                        label="Tipo do Certificado"
                        noOptionsText="Nenhum registro encontrado"
                        required
                        // multiple
                        options={optionsCertificateTypes}
                      />
                    </Grid>

                    <Grid item md={12} xs={12}>
                      <Autocomplete
                        id="combo_certification_category_id"
                        name="certification_category_id"
                        label="Categoria do Certificado"
                        noOptionsText="Nenhum registro encontrado"
                        // multiple
                        options={optionsCertificateCategories}
                      />
                    </Grid>

                    <Grid item md={12} xs={12}>
                      <YearPickerInput
                        name="contract_year"
                        id="contract_year"
                        label="Ano do Contrato"
                        required
                        autoOk
                        margin="normal"
                        inputVariant="outlined"
                        format="yyyy"
                        invalidDateMessage="Data Inválida"
                        invalidLabel="Data Inválida"
                        cancelLabel="CANCELAR"
                      />
                      {/* <TextInput
                        name="contract_year"
                        label="Ano do Contrato"
                        variant="outlined"
                        type="number"
                        fullWidth
                        required
                      /> */}
                    </Grid>

                    <Grid item md={12} xs={12}>
                      <TextInput
                        name="revision_number"
                        label="Revisão"
                        variant="outlined"
                        type="number"
                        fullWidth
                      />
                    </Grid>

                    <Grid item md={12} xs={12}>
                      <TextInput
                        name="scope"
                        label="Escopo"
                        variant="outlined"
                        multiline
                        rows={3}
                        fullWidth
                      />
                    </Grid>

                    <Grid item md={12} xs={12}>
                      <TextInput
                        name="normatives"
                        label="Normativas"
                        variant="outlined"
                        multiline
                        rows={3}
                        fullWidth
                      />
                    </Grid>

                    <Grid item md={12} xs={12}>
                      <DatePickerInput
                        name="first_concession"
                        id="first_concession"
                        label="Primeira Concessão"
                        margin="normal"
                        format="dd/MM/yyyy"
                        inputVariant="outlined"
                        InputAdornmentProps={{ position: 'start' }}
                        invalidDateMessage="Data Inválida"
                        cancelLabel="CANCELAR"
                      />
                    </Grid>

                    <Grid item md={6} xs={6}>
                      <DatePickerInput
                        id="validity_start"
                        name="validity_start"
                        label="Validade Inicial"
                        margin="normal"
                        format="dd/MM/yyyy"
                        inputVariant="outlined"
                        InputAdornmentProps={{ position: 'start' }}
                        invalidDateMessage="Data Inválida"
                        cancelLabel="CANCELAR"
                      />
                    </Grid>

                    <Grid item md={6} xs={6}>
                      <DatePickerInput
                        id="validity_end"
                        name="validity_end"
                        label="Validade Final"
                        margin="normal"
                        format="dd/MM/yyyy"
                        inputVariant="outlined"
                        InputAdornmentProps={{ position: 'start' }}
                        invalidDateMessage="Data Inválida"
                        cancelLabel="CANCELAR"
                      />
                    </Grid>

                    <Grid item md={12} xs={12}>
                      <Autocomplete
                        id="combo_certificate_situation_id"
                        name="certificate_situation_id"
                        label="Situação do Certificado"
                        noOptionsText="Nenhum registro encontrado"
                        // multiple
                        options={optionsCertificateSituations}
                      />
                    </Grid>

                    <Grid item md={12} xs={12}>
                      <FileInput
                        name="certificate_file"
                        label="Anexo do Certificado"
                        id="certificate_file"
                      />
                    </Grid>
                    {/* <Grid item md={12} xs={12}>
                      <CardActions>
                        <Button color="primary" fullWidth variant="text">
                          Selecionar Arquivo do Certificado
                        </Button>
                      </CardActions>
                    </Grid> */}
                    <Grid item md={12} xs={12}>
                      <Switch
                        name="is_published"
                        legend="Publicação"
                        label="Certificado publicado?"
                        helpText="Quando publicado o certificado é visível para o cliente"
                        // checked={isPublished}
                        // onChange={handleChangeIsPublished}
                        size="medium"
                        color="primary"
                      />
                    </Grid>
                  </Grid>
                </CardContent>

                <Divider />

                <Box display="flex" justifyContent="flex-end" p={2}>
                  <Button type="submit" color="primary" variant="contained">
                    Salvar
                  </Button>
                </Box>
              </Card>
            </Form>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default CertificateCreate;
