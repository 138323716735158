import React, { useRef, useCallback, useState, useEffect } from 'react';
import {
  Container,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Box,
  Button,
  CircularProgress,
  makeStyles,
} from '@material-ui/core';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useNavigate, useParams } from 'react-router-dom';

import api from '../../../services/api';
import Yup from '../../../utils/validators/Yup';
import { getValidationErrors, getApiErrors } from '../../../utils/getErrors';
import { useToast } from '../../../hooks/toast';

import Page from '../../../components/Layouts/Page';
import TextInput from '../../../components/Form/TextInput';
import TextInputMask from '../../../components/Form/TextInputMask';
// import TextInputMask3 from '../../../components/Form/TextInputMask3';
// import TextInputMask2 from '../../../components/Form/TextInputMask2';

interface CompanyFormData {
  name: string;
  identification_code: string;
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#F4F6F8',
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const CompanyEdit: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const navigate = useNavigate();
  const { companyId } = useParams();
  const classes = useStyles();

  const [loadingCompany, setLoadingCompany] = useState<boolean>(true);
  const [company, setCompany] = useState({});

  const handleSubmit = useCallback(
    async (data: CompanyFormData) => {
      try {
        formRef.current?.setErrors({});

        const schemaForm = Yup.object().shape({
          name: Yup.string().required('Campo obrigatório'),
          identification_code: Yup.string()
            .isCNPJ('CNPJ inválido')
            .required('CPF é obrigatório'),
        });

        await schemaForm.validate(data, { abortEarly: false });

        const { name, identification_code } = data;

        const formData = { name, identification_code };

        await api.put(`/admin/companies/${companyId}`, formData);

        addToast({
          type: 'success',
          title: 'Sucesso!',
          description: 'Dados da empresa atualizados com sucesso!',
        });

        navigate('/companies');
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          // console.log('Yup.ValidationError => ', error);

          formRef.current?.setErrors(errors);

          addToast({
            type: 'error',
            title: 'Erro',
            delay: 10000,
            description: 'Verifique os erros no formulário',
          });
          return;
        }

        addToast({
          type: 'error',
          title: 'Erro',
          delay: 10000,
          description: getApiErrors(error),
        });
      }
    },
    [addToast, navigate, companyId],
  );

  const loadCompany = useCallback(async () => {
    setLoadingCompany(true);
    try {
      const response = await api.get(`/admin/companies/${companyId}`);
      setCompany(response.data?.company);
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Erro',
        delay: 10000,
        description: 'Erro ao carregar os dados da empresa',
      });
    }
    setLoadingCompany(false);
  }, [companyId, addToast]);

  useEffect(() => {
    loadCompany();
  }, [loadCompany]);

  return (
    <Page className={classes.root}>
      <Container maxWidth="lg">
        <Grid container spacing={1}>
          <Grid item lg={12} md={12} xs={12}>
            <Form
              ref={formRef}
              initialData={company}
              onSubmit={handleSubmit}
              autoComplete="off"
              noValidate
              className={classes.root}
            >
              <Card>
                <CardHeader title="Empresa" subheader="Editar" />

                <Divider />
                {!loadingCompany ? (
                  <>
                    <CardContent>
                      <Grid container spacing={1}>
                        <Grid item md={12} xs={12}>
                          <TextInput
                            name="name"
                            label="Razão Social"
                            variant="outlined"
                            fullWidth
                          />
                        </Grid>

                        <Grid item md={12} xs={12}>
                          <TextInputMask
                            name="identification_code"
                            placeholder="CNPJ"
                            mask="99.999.999/9999-99"
                          />
                        </Grid>

                        {/* <Grid item md={12} xs={12}>
                          <TextInputMask2
                            name="identification_code"
                            label="CNPJ"
                            mask="99.999.999/9999-99"
                          />
                        </Grid>

                        <Grid item md={12} xs={12}>
                          <TextInputMask3
                            name="identification_code"
                            label="CNPJ"
                            mask="99.999.999/9999-99"
                          />
                        </Grid> */}
                      </Grid>
                    </CardContent>

                    <Divider />

                    <Box display="flex" justifyContent="flex-end" p={2}>
                      <Button type="submit" color="primary" variant="contained">
                        Salvar
                      </Button>
                    </Box>
                  </>
                ) : (
                  <CircularProgress color="primary" size={100} />
                )}
              </Card>
            </Form>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default CompanyEdit;
