import React, { useRef, useCallback, useState, useEffect } from 'react';
import {
  Container,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Box,
  Button,
  makeStyles,
} from '@material-ui/core';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useNavigate } from 'react-router-dom';

import api from '../../../services/api';
import Yup from '../../../utils/validators/Yup';
import { getValidationErrors, getApiErrors } from '../../../utils/getErrors';
import { useToast } from '../../../hooks/toast';

import Page from '../../../components/Layouts/Page';
import TextInput from '../../../components/Form/TextInput';
import TextInputMask from '../../../components/Form/TextInputMask';
import Autocomplete from '../../../components/Form/Autocomplete';
import Switch from '../../../components/Form/Switch';

interface IRole {
  id: number;
  role?: string;
  alias?: string;
}

interface UserFormData {
  name: string;
  identification_document: string;
  job_title: string;
  cellphone_number: string;
  email: string;
  password: string;
  is_active: boolean;
  companies: string[];
  roles: string[];
}

interface ICompany {
  id: number;
  name: string;
  identification_code: string;
  formated_identification_code: string;
}

interface AutocompleteOptions {
  label: string;
  value: string | number;
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#F4F6F8',
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const UserCreate: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const navigate = useNavigate();
  const classes = useStyles();

  const [companies, setCompanies] = useState<AutocompleteOptions[]>([]);
  const [loadingCompanies, setLoadingCompanies] = useState<boolean>(true);
  const [companiesSearchTerm] = useState<string>('');

  const [roles, setRoles] = useState<AutocompleteOptions[]>([]);
  const [loadingRoles, setLoadingRoles] = useState<boolean>(true);
  const [rolesSearchTerm] = useState<string>('');

  const loadCompanies = useCallback(async () => {
    setLoadingCompanies(true);
    const response = await api.get('/admin/companies', {
      params: {
        companiesSearchTerm,
        // page,
        // resultsPerPage,
      },
    });

    const formatedCompanies = response.data?.companies?.map(
      (company: ICompany) => ({
        label: `${company.name} - (${company.identification_code})`,
        value: company.id,
      }),
    );

    setCompanies(formatedCompanies);
    setLoadingCompanies(false);
  }, [companiesSearchTerm]);

  const loadRoles = useCallback(async () => {
    setLoadingRoles(true);
    const response = await api.get('/admin/roles', {
      params: {
        rolesSearchTerm,
        // page,
        // resultsPerPage,
      },
    });

    const formatedRoles = response.data?.roles?.map((role: IRole) => ({
      label: role.role,
      value: role.id,
    }));

    setRoles(formatedRoles);
    setLoadingRoles(false);
  }, [rolesSearchTerm]);

  useEffect(() => {
    loadCompanies();
    loadRoles();
  }, [loadCompanies, loadRoles]);

  const handleSubmit = useCallback(
    async (data: UserFormData) => {
      try {
        formRef.current?.setErrors({});

        const schemaForm = Yup.object().shape({
          name: Yup.string().required('Campo obrigatório'),
          job_title: Yup.string().required('Campo obrigatório'),
          cellphone_number: Yup.string().required('Campo obrigatório'),
          identification_document: Yup.string()
            .isCPF('CPF inválido')
            .required('Campo obrigatório'),
          email: Yup.string()
            .required('Campo obrigatório')
            .email('Digite um e-mail válido'),
          password: Yup.string().min(6, 'No mínimo 6 dígitos'),
          companies: Yup.array()
            .of(Yup.string().required())
            .strict(true)
            .nullable()
            .required('Campo obrigatório'),
          roles: Yup.array()
            .of(Yup.string().required())
            .strict(true)
            .nullable()
            .required('Campo obrigatório'),
        });

        await schemaForm.validate(data, { abortEarly: false });

        const {
          name,
          job_title,
          cellphone_number,
          identification_document,
          email,
          password,
          is_active,
          companies: companiesIds,
          roles: rolesIds,
        } = data;

        const formData = {
          name,
          job_title,
          cellphone_number,
          identification_document,
          email,
          password,
          is_active,
          companies: companiesIds,
          roles: rolesIds,
        };

        await api.post(`/admin/users/`, formData);

        addToast({
          type: 'success',
          title: 'Sucesso!',
          description: 'Usuário cadastrado com sucesso!',
        });

        navigate('/users');
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          // console.log('Yup.ValidationError => ', error);

          formRef.current?.setErrors(errors);

          addToast({
            type: 'error',
            title: 'Erro',
            delay: 10000,
            description: 'Verifique os erros no formulário',
          });
          return;
        }

        addToast({
          type: 'error',
          title: 'Erro',
          delay: 10000,
          description: getApiErrors(error),
        });
      }
    },
    [addToast, navigate],
  );

  return (
    <Page className={classes.root}>
      <Container maxWidth="lg">
        <Grid container spacing={1}>
          <Grid item lg={12} md={12} xs={12}>
            <Form
              ref={formRef}
              onSubmit={handleSubmit}
              autoComplete="off"
              noValidate
              className={classes.root}
            >
              <Card>
                <CardHeader title="Usuário" subheader="Cadastrar" />

                <Divider />
                <CardContent>
                  <Grid container spacing={1}>
                    <Grid item md={12} xs={12}>
                      <TextInput
                        name="name"
                        label="Nome"
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>

                    <Grid item md={12} xs={12}>
                      <TextInput
                        name="email"
                        label="Email"
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>

                    <Grid item md={12} xs={12}>
                      <TextInputMask
                        name="identification_document"
                        placeholder="CPF"
                        mask="999.999.999-99"
                      />
                    </Grid>

                    <Grid item md={12} xs={12}>
                      <TextInput
                        name="job_title"
                        label="Cargo / Função"
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>

                    <Grid item md={12} xs={12}>
                      <TextInputMask
                        name="cellphone_number"
                        placeholder="Celular"
                        mask="(99) 99999-9999"
                      />
                    </Grid>

                    <Grid item md={12} xs={12}>
                      <TextInput
                        name="password"
                        label="Senha"
                        type="password"
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>

                    <Grid item md={12} xs={12}>
                      <Switch
                        name="is_active"
                        legend="Usuário Ativo"
                        label="Usuário Habilitado?"
                        helpText="Quando habilitado o usuário pode efetuar login e utilizar o sistema"
                        // checked={isPublished}
                        // onChange={handleChangeIsPublished}
                        size="medium"
                        color="primary"
                      />
                    </Grid>

                    <Grid item md={12} xs={12}>
                      <Autocomplete
                        id="combo_companies"
                        name="companies"
                        label="Empresas"
                        noOptionsText="Nenhum registro encontrado"
                        multiple
                        required
                        options={companies}
                        loading={loadingCompanies}
                      />
                    </Grid>

                    <Grid item md={12} xs={12}>
                      <Autocomplete
                        id="combo_roles"
                        name="roles"
                        label="Grupos Acesso"
                        noOptionsText="Nenhum registro encontrado"
                        multiple
                        required
                        options={roles}
                        loading={loadingRoles}
                      />
                    </Grid>
                  </Grid>
                </CardContent>

                <Divider />

                <Box display="flex" justifyContent="flex-end" p={2}>
                  <Button type="submit" color="primary" variant="contained">
                    Salvar
                  </Button>
                </Box>
              </Card>
            </Form>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default UserCreate;
