import React, { useEffect, useRef, useState, useCallback } from 'react';
import 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import { useField } from '@unform/core';

import { DatePickerProps } from './types';
import { Container } from './styles';

const DatePickerInput: React.FC<DatePickerProps> = ({
  name,
  id,
  label,
  value,
  helperText,
  ...restProps
}) => {
  const { fieldName, registerField, defaultValue = null, error } = useField(
    name,
  );

  const inputRef = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState<Date | null>(
    value ?? defaultValue,
  );
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const _handleChange = useCallback(
    (date: Date | null) => {
      setInputValue(date);
    },
    [setInputValue],
  );

  useEffect(() => {
    if (fieldName) {
      registerField({
        name: fieldName,
        ref: inputRef.current,
        getValue() {
          return inputValue;
        },
        setValue(_, newValue: Date | null) {
          // console.log('registerField -> setValue =>', newValue);
          // ref.value = newValue;
          _handleChange(newValue);
        },
      });
    }
  }, [fieldName, registerField, _handleChange, inputValue]);

  return (
    <Container>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
        <KeyboardDatePicker
          {...restProps}
          inputRef={inputRef}
          name={name}
          id={id}
          label={label}
          error={!!error}
          helperText={error || helperText}
          open={isOpen}
          onClick={() => setIsOpen(true)}
          onOpen={() => setIsOpen(true)}
          onClose={() => setIsOpen(false)}
          value={inputValue}
          onChange={_handleChange}
        />
      </MuiPickersUtilsProvider>
    </Container>
  );
};

export default React.memo(DatePickerInput);
